import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GetStarted from "../components/sections/GetStarted";

import heroImg from "../images/img-subscription.png";

import marketIcon1 from "../images/hashtag.svg";
import marketIcon2 from "../images/trend-up.svg";

import cardtik from "../images/card-tick.svg";
import flash from "../images/flash.svg";

const Subscription = () => {
  return (
    <Layout mainbg="grey">
      <SEO title={`Subscription models - Digimeth.net`} />
      <section className="typical__padding co-bg--grey co-head">
        <div className="site__container grid">
          <div className="left">
            <div className="content--wrapper">
              <div className="content">
                <h2>
                  <span className="colorized">Subscription</span>
                </h2>
                <h3 className="description">Solution to grow your sales</h3>
                <span className="descroption">
                  Whether your company sells a product or service, you should
                  consider using the subscription business model for regular
                  sales. The subscription business model has proven to be
                  profitable.
                </span>
              </div>
            </div>
          </div>

          <div className="right">
            <picture className="fluid__wrapper">
              <img alt="Integration" src={heroImg} />
            </picture>
          </div>
        </div>
      </section>

      <section className="co-bg--white typical__padding co-section">
        <div className="co-section--wrapper">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">The market</span>
                    <br />
                    <span className="default">for subscription products:</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="site__container grid">
            <div className="half">
              <div className="icon">
                <img src={marketIcon1} alt="hashtag" />
              </div>
              <p className="description large">
                Is available for every type of business: grocery delivery,
                streaming services, online training, email newsletters, etc.
              </p>
            </div>
            <div className="half">
              <div className="icon">
                <img src={marketIcon2} alt="trends" />
              </div>
              <p className="description large">
                Has grown nearly 6-fold in the last nine years
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="co-bg--black typical__padding co-section co-dark">
        <div className="co-section--wrapper">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Why should a business</span>
                    <br />
                    <span className="default">
                      {" "}
                      implement the subscription model?
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="site__container grid">
            <div className="half">
              <div className="icon">
                <img src={flash} alt="flash" />
              </div>
              <p className="description medium white">
                Even e-commerce brands are using subscriptions to drive sales.
                That is because the consumer market has embraced this model,
                which has increased its popularity.
              </p>
            </div>
            <div className="half">
              <div className="icon">
                <img src={cardtik} alt="cardtick" />
              </div>
              <p className="description medium white">
                All you need is to convince users to attach a bank card,
                continuously give them value, and monitor subscription statuses
                and payments. Once you can get customers to pay for
                subscriptions, you'll have regular sales on a monthly or annual
                basis. This guarantees you steady growth over time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="co-bg--grey typical__padding co-section">
        <div className="co-section--wrapper">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">The numbers</span>
                    <br />
                    <span className="default">say that:</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="site__container ">
            <div className="grid trinity">
              <div className="grid-trinity--card">
                <div className="content">
                  <h3 className="title">70%</h3>
                  <p className="description">
                    of business leaders consider subscription business models as
                    a key to prospects in 2023
                  </p>
                </div>
              </div>
              <div className="grid-trinity--card">
                <div className="content">
                  <h3 className="title">53%</h3>
                  <p className="description">
                    of all software revenue will be generated from a
                    subscription model by 2022
                  </p>
                </div>
              </div>
              <div className="grid-trinity--card">
                <div className="content">
                  <h3 className="title">30-50%</h3>
                  <p className="description">
                    is the average annual subscription billing vendor growth
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetStarted isGetStarted={true} title="Ready to dive in?" />
    </Layout>
  );
};

export default Subscription;
